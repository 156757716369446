<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns is-multiline">
        <div 
          class="column" 
          v-if="!isOutdated"
        >
          <SimilarListingsControls />
        </div>
        <div
          class="column is-narrow" 
          v-if="!isOutdated"
        >
          <SimilarListingsSort
            :location="activeFilters.includes('distance')"
          />
        </div>
        <div
          class="column is-12 has-background-white is-sticky"
          v-if="!isOutdated"
        >
          <div v-if="listing && !listing.sold && listing.listingPrice">
            <p class="title is-6 has-text-dark">
              <span>{{ vrm }} is currently for sale</span>
            </p>
            <VehicleListing
              class="mb-5"
              v-bind="{ listing, isListing: true }"
            />
          </div>
          <p
            v-if="total"
            class="title is-6 has-text-dark"
            :class="{ 'mb-3': $mq === 'mobile' }"
          >
            {{ total }} similar vehicles found ({{ onSale }} currently for sale)
          </p>
          <div class="is-hidden-mobile">
            <SimilarListingsHeaders class="px-4" />
          </div>
        </div>
        <div 
          v-if="loading"
          class="column is-12"
        >
          <Spinner :size="'is-large'" />
        </div>
        <div
          class="column is-12"
          v-for="(listing, index) in listings"
          :key="index"
        >
          <VehicleListing v-bind="{ listing }" />
        </div>
        <div
          class="column is-12"
          v-if="!isEmpty && !loading && listings && listings.length"
        >
          <div class="field">
            <button
              class="button is-medium is-fullwidth is-info"
              @click="loadMore"
              :disabled="isDepleted"
              v-if="!timeout"
            >
              <span class="icon">
                <i
                  :class="`fal ${isDepleted ? 'fa-times' : 'fa-plus-hexagon'}`"
                />
              </span>
              <span v-if="isDepleted">You've reached the end</span>
              <span v-else>Load more</span>
            </button>
            <button
              class="button is-medium is-fullwidth is-dark"
              @click="clearAndFetch"
              v-else-if="timeout"
            >
              <span class="icon">
                <i class="fal fa-sync-alt" />
              </span>
              <span>Session timeout</span>
            </button>
          </div>
          <p 
            v-if="timeout"
            class="has-text-grey has-text-centered" 
          >
            Sorry &mdash; You'll have to refresh the listings to browse again
          </p>
        </div>
      </div>
      <div
        class="columns is-vcentered has-text-centered"
        v-if="isOutdated && !loading"
      >
        <div class="column is-12">
          <TableEmpty
            text="VRM no longer registered to vehicle"
            subtext="Please search for the new VRM to find similar listings"
          />
        </div>
      </div>
      <div
        class="columns is-vcentered has-text-centered"
        v-else-if="isEmpty && !loading"
      >
        <div class="column is-12">
          <TableEmpty
            text="No listings available"
            :subtext="`We couldn't find a similar ${make} with this criteria`"
          />
        </div>
      </div>
    </div>
    <transition enter-active-class="animated rubberBand">
      <div 
        class="back-to-top" 
        v-if="listings"
      >
        <button
          @click="scrollToTop"
          class="button is-medium is-info"
          title="Back to top"
          v-tippy="{ placement: 'left' }"
        >
          <span class="icon">
            <i class="fal fa-arrow-circle-up" />
          </span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'CompanionMarketOverview',
  data: () => ({
    timeout: 0
  }),
  components: {
    TableEmpty: () => import('core/components/TableEmpty'),
    SimilarListingsHeaders: () =>
      import(
        'modules/companion/components/similar-listings/SimilarListingsHeaders'
      ),
    SimilarListingsControls: () =>
      import(
        'modules/companion/components/similar-listings/SimilarListingsControls'
      ),
    SimilarListingsSort: () =>
      import(
        'modules/companion/components/similar-listings/SimilarListingsSort'
      ),
    VehicleListing: () =>
      import('modules/companion/components/section/VehicleListing'),
    Spinner: () => import('core/components/Spinner')
  },
  methods: {
    ...mapActions({
      fetch: 'companion/similarListings/fetch',
      clearAndFetch: 'companion/similarListings/clearAndFetch'
    }),
    async loadMore() {
      const scrollPosition = document.documentElement.scrollTop
      try {
        await this.fetch()
      } catch (err) {
        this.$notify('Listings unavailable')
      } finally {
        if (scrollPosition) window.scrollTo(0, scrollPosition)
      }
    },
    scrollToTop() {
      return window.scrollTo(0, 0)
    }
  },
  computed: {
    isEmpty() {
      return Array.isArray(this.listings) && this.listings.length === 0
    },
    isDepleted() {
      return Array.isArray(this.listings) && this.listings.length >= this.total
    },
    ...mapGetters('companion', [
      'make',
      'listing',
      'vrm',
      'isOutdated',
      'specs'
    ]),
    ...mapGetters('companion/similarListings', [
      'listings',
      'total',
      'sortBy',
      'loading',
      'onSale'
    ]),
    ...mapGetters({
      activeFilters: 'companion/similarListings/filterBy/active',
    })
  }
}
</script>

<style lang="sass" scoped>
.back-to-top
  position: fixed
  right: 1rem
  bottom: 1rem
  z-index: 1
.is-sticky
  box-shadow: 0 1rem 1rem -1rem $grey-lightest
  z-index: 1
  position: sticky
  top: 0
</style>

<style lang="sass">
.vrm-column
  max-width: 8.5rem
.year-column
  max-width: 5rem
.colour-column
  max-width: 7rem
.dealer-column
  max-width: 11rem
</style>
